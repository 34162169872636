<template>
  <div style="background: #cfd1cc">
    <div v-if="!agreementAgreed">
      <div class="main">
        <div class="main-title">产品说明</div>
        <div class="query-info-wrapper" v-if="!agreementAgreed">
          <div class="questionnaire">
            <p>
              您好！欢迎使用灯塔智能生涯规划与数据分析系统！在使用系统前，您需要先了解关于本系统的说明。
            </p>
            <p>
              A)
              本网站含有的《专业定位测评报告》，《学科能力测评报告》，《选科规划报告》是基于公开权威测评量表以及学生的心理特征与学科能力产生的。它们基于客观事实以及智能算法得到，可帮助学生进行高效选科，并给出适合学生的大学专业与学校建议。
            </p>
            <p>
              B)
              请在相对安静无打扰的环境进行以上测评，以确保得到高质量可靠的测评结果，否则可能影响测评的准确性。
            </p>
            <p>
              C)
              本网站含有的测评结果与相关报告可帮助学生进行高效选科，但它们本质上是高效决策工具以及决策辅助手段，选科与选择专业的最终决定权是学生的，这些报告不对学生的最终选择结果负责。
            </p>
            <p>
              D)
              本网站包含的测评以及测评报告中的部分图片，数据以及文字信息来自互联网公开资料以及权威部门授权。不排除个别的数据信息源不够准确的可能，本网站不对第三方错误信息负责，本网站的所有报告解释权归测评系统开发者所有。
            </p>
            <p>
              E)
              《专业定位测评报告》基于心理学权威量表以及专业智能算法，可以比较客观地反应测评者的学科倾向。由于报告基于客观事实，呈现的结果可能与测评者主观自我认知不完全一致。
            </p>
            <p>
              F)
              《学科能力测评报告》基于部分高考真题，并通过自主开发的测评算法得到。它反应学生各个学科的客观应试能力，而《专业定位测评报告》中的“优势学科报告”主要反应学生对学科的主观喜好与自我认定的能力，这两者有一定联系，但也存在区别。
            </p>
            <div class="button-wrapper">
              <button
                class="lj-btn lj-btn-third"
                @click="onDisagreeBtnClick"
                style="margin-right: 1rem"
              >
                不同意</button
              ><button
                class="lj-btn lj-btn-primary"
                @click="onAgreeBtnClick"
                style="margin-left: 1rem"
              >
                同意
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="main">
        <div class="main-title">专业匹配测试</div>
        <div
          class="question-wrapper lj-u-sm-10 lj-u-sm-offset-1 lj-u-lg-6 lj-u-lg-offset-3 lj-u-end"
        >
          <div class="question-control"></div>
          <div class="question" v-if="displayIntro === 1">
            <p>
              您好，欢迎使用灯塔智能生涯规划与数据分析系统！众所周知，兴趣是人们从事某项工作的动力。科学认识自我的兴趣是做出专业选择的第一步。
            </p>
            <p>
              接下来您将完成第一部分和第二部分的试题（共四部分），用于评估您的职业倾向，用时约15分钟。
            </p>
            <p>完成这部分测评后，您将得到《职业倾向报告》。</p>
            <button class="lj-btn lj-btn-primary" @click="changeDisplayIntro()">
              开始测评
            </button>
          </div>
          <div class="question" v-if="displayIntro === 2">
            <p>
              您好！每个人都会沿着自己所属的类型发展出个人行为、技巧和态度，而每一种也都存在着自己的潜能和潜在的盲点。
            </p>
            <p>
              接下来您将完成第三部分的试题（共四部分），用于评估您的性格倾向，即性格类型与相关职业的匹配程度，用时约8分钟。
            </p>
            <p>完成这部分测评后，您将得到《人格倾向报告》。</p>
            <button class="lj-btn lj-btn-primary" @click="changeDisplayIntro()">
              开始测评
            </button>
          </div>
          <div class="question" v-if="displayIntro === 3">
            <p>
              您好！接下来是最后一部分的试题，在试题全部完成后，您将得到《学科兴趣倾向报告》与《专业全匹配报告》
            </p>
            <p>
              《专业匹配分析报告》包括最适合与最不适合的高校大类专业报告、最适合的高校大类专业详细报告、最适合的高校大类专业内小类专业排序报告、高校大类专业匹配全景报告。
            </p>
            <p>最后一部分的试题用时约5分钟，加油！</p>
            <button class="lj-btn lj-btn-primary" @click="changeDisplayIntro()">
              开始测评
            </button>
          </div>

          <!-- 实际的问题part -->
          <div class="question" v-if="displayIntro === 0">
            <div
              class="question-percent-wrapper part"
              ref="questionPercentWrapperPart"
              style="display: block"
            >
              <el-tooltip content="当前部分题目进度"
                ><span class="percent-text">{{ percent }}</span></el-tooltip
              >
            </div>
            <div
              class="question-percent-wrapper total"
              ref="questionPercentWrapperTotal"
              style="opacity: 0"
            ></div>
            <div>
              <div v-if="part === 1 || part === 2 || part === 4">
                <div class="part-title">
                  {{ maintitle }}
                </div>
                <div class="part-progress lj-progress lj-progress-xs">
                  <div
                    class="lj-progress-bar"
                    :style="{ width: percent }"
                  ></div>
                </div>
                <div class="content">
                  <span>{{ currentQuestion + 1 }}. {{ question.content }}</span>
                </div>
                <div class="options">
                  <button
                    class="lj-btn lj-btn-block option"
                    @click="onChooseChoice(1, '')"
                  >
                    1. 完全不符合</button
                  ><button
                    class="lj-btn lj-btn-block option"
                    @click="onChooseChoice(2, '')"
                  >
                    2. 不太符合</button
                  ><button
                    class="lj-btn lj-btn-block option"
                    @click="onChooseChoice(3, '')"
                  >
                    3. 大致符合</button
                  ><button
                    class="lj-btn lj-btn-block option"
                    @click="onChooseChoice(4, '')"
                  >
                    4. 比较符合</button
                  ><button
                    class="lj-btn lj-btn-block option"
                    @click="onChooseChoice(5, '')"
                  >
                    5. 完全符合
                  </button>
                </div>
              </div>

              <div v-if="part === 3">
                <div class="part-title">
                  第三部分 : 请从上下两句话中选出一个更贴合你实际情况的选项
                </div>
                <div class="part-progress lj-progress lj-progress-xs">
                  <div
                    class="lj-progress-bar"
                    :style="{ width: percent }"
                  ></div>
                </div>
                <div class="content">
                  <span>第{{ currentQuestion + 1 }}题</span>
                </div>
                <div class="options">
                  <button
                    class="lj-btn lj-btn-block option"
                    @click="onChooseChoice(20, question.l_type)"
                  >
                    A. {{ question.l_content }}</button
                  ><button
                    class="lj-btn lj-btn-block option"
                    @click="onChooseChoice(20, question.r_type)"
                  >
                    B. {{ question.r_content }}
                  </button>
                </div>
              </div>

              <div class="total-progress">
                <div class="part part1" :class="{ active: part >= 1 }">
                  第一部分
                </div>
                <div class="part part2" :class="{ active: part >= 2 }">
                  第二部分
                </div>
                <div class="part part3" :class="{ active: part >= 3 }">
                  第三部分
                </div>
                <div class="part part4" :class="{ active: part >= 4 }">
                  第四部分
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tip">{{ message }}</div>
      </div>
      <div
        class="button-wrapper"
        style="text-align: center; margin-bottom: 10px; padding: 0 10px"
      >
        <a
          class="lj-btn lj-btn-third lj-btn-block back-btn"
          @click="BackToContent()"
          >保存进度，返回测评导航</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { infoquery, agreeliability } from './../../../api/userinfo'
import { queryQuesAndSave, queryMajorDone } from './../../../api/major'
export default {
  data: function () {
    return {
      agreementAgreed: false,

      currentQuestion: 0,
      isClicked: !1,
      tooFastLimit: !0,
      message: '',
      question: {
        content: '我可以做啥',
        l_content: '11212',
        r_content: '121212'
      },
      displayIntro: null, // 1,2,3是展示文字说明。0是做题的部分,
      postCurQues: null
    }
  },

  computed: {
    part: function () {
      let res
      if (this.currentQuestion === -1) {
        res = 0
      } else if (this.currentQuestion < 26) {
        res = 1
      } else if (this.currentQuestion < 53) {
        res = 2
      } else if (this.currentQuestion < 78) {
        res = 3
      } else if (this.currentQuestion < 133) {
        res = 4
      } else {
        // this.$message.error('out of bounds')
      }
      return res
    },
    // 根据part得到目前具体需要的是哪个问题
    trueCurrentQuestion: function () {
      if (this.part) {
        var e = 0
        switch (this.part) {
          case 1:
            e = this.currentQuestion
            break
          case 2:
            e = this.currentQuestion - 26
            break
          case 3:
            e = this.currentQuestion - 53
            break
          case 4:
            e = this.currentQuestion - 78
            break
          default:
            console.error('错误！超出范围的part！')
        }
        return e
      }
      return 0
    },
    length: function () {
      let res
      switch (this.part) {
        case 1:
          res = 26
          break
        case 2:
          res = 53 - 26
          break
        case 3:
          res = 78 - 53
          break
        case 4:
          res = 133 - 78
          break
        default:
          console.error('错误！超出范围的part！')
      }
      return res
    },
    percent: function () {
      return (
        (this.length
          ? Math.round((this.trueCurrentQuestion / this.length) * 100)
          : 0) + '%'
      )
    },
    maintitle: function () {
      return this.part === 1
        ? '第一部分 : 请选择出一个最贴合你实际情况的选项'
        : this.part === 2
        ? '第二部分 : 请选择出一个最贴合你实际情况的选项'
        : '第四部分 : 请选择出一个最贴合你实际情况的选项'
    },
    isMobile: function () {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    }
  },
  methods: {
    BackToContent: function () {
      this.$router.back()
    },
    jumpPage: function (dest) {
      dest === '/'
        ? this.$router.replace({ path: dest })
        : this.$router.push({ path: dest })
    },
    changeDisplayIntro: function () {
      this.displayIntro = 0
    },
    getDisplayIntro: function () {
      switch (this.currentQuestion) {
        case 0:
          this.displayIntro = 1
          break
        case 53:
          this.displayIntro = 2
          break
        case 78:
          this.displayIntro = 3
          break
        default:
          this.displayIntro = 0
      }
    },
    onChooseChoice: function (score, type) {
      var t = this
      if (this.tooFastLimit) {
        this.$message.error('您的答题速度过快，请认真读题后再作答')
        return
      }
      if (this.isClicked) {
        this.isClicked = !0
      }
      queryQuesAndSave({
        cur_q: this.postCurQues,
        score: score,
        part: this.part,
        type: type || this.question.type
      }).then(res => {
        if (res.rescode == 200) {
          console.log('queryQuesAndSave', res)
          this.question = res.question
          this.currentQuestion++
          this.postCurQues = res.cur_question
          this.tooFastLimit = !0
          setTimeout(function () {
            t.tooFastLimit = !1
          }, 1e3)
          this.isClicked = !1
          console.log(this.currentQuestion)
          if (53 === this.currentQuestion) {
            this.jumpPage('HomeMajorStdResultPage1')
          } else if (78 == this.currentQuestion) {
            this.jumpPage('HomeMajorStdResultPage2')
          } else if (133 == this.currentQuestion) {
            this.jumpPage('HomeMajorStdResultPage3')
          }
        }
      })
    },

    /**
     * 产品说明，同意或者不同意
     */
    onDisagreeBtnClick: function () {
      this.$router.back()
    },
    onAgreeBtnClick: function () {
      agreeliability({
        agreemajormatching: 1
      }).then(res => {
        if (res.rescode == 200) {
          this.agreementAgreed = !0
          this.queryQuesAndSaveMethods()
        }
      })
    },

    queryQuesAndSaveMethods: function () {
      queryMajorDone({}).then(res => {
        if (res.rescode == 200) {
          console.log('queryMajorDone', res)
          this.currentQuestion = res.cur_q
          if (this.currentQuestion == -1) {
            this.$router.replace({
              path: 'reportCenter'
            })
            return
          } else {
            this.getDisplayIntro()
            queryQuesAndSave({
              cur_q: this.trueCurrentQuestion,
              score: '',
              part: this.part,
              type: ''
            }).then(res => {
              if (res.rescode == 200) {
                console.log('queryQuesAndSave', res)
                this.question = res.question
                this.postCurQues = res.cur_question
              }
            })
          }
        }
      })
    }
  },
  mounted: function () {
    var t = this
    setTimeout(function () {
      t.tooFastLimit = !1
    }, 1e3)

    infoquery({}).then(res => {
      if (res.rescode == 200) {
        this.agreementAgreed = res.agreemajormatching
        this.queryQuesAndSaveMethods()
      }
    })
  }
}
</script>

<style lang="less" scoped>
@import url('./../../../Public/resources/css/components/base.min.css');
@import url('./../../../Public/resources/css/components/icon.min.css');
@import url('./../../../Public/resources/css/components/nav.min.css');
@import url('./../../../Public/resources/css/components/grid.min.css');
@import url('./../../../Public/resources/css/components/footer.min.css');
@import url('./../../../Public/resources/css/components/card.min.css');
@import url('./../../../Public/resources/css/components/button.min.css');
@import url('./../../../Public/resources/css/components/progress.min.css');
@import url('./../../../Public/resources/css/components/utility.min.css');
@import url('./../../../Public/resources/css/templates/home/major/std_matching.min.css');
* {
  text-align: left;
}

.back-btn {
  text-align: center;
}

.consult-btn {
  text-align: center;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
}

@media only screen and (min-width: 481px) {
  .button-wrapper {
    /* width: 50%; */
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
}
</style>
